<template>
  <div role="main" class="main">
    <section class="common-page minh-100 guestbook-registration image-page">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="border-l text-left">
              <p>Profile</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3">
            <h4 class="text-yellow mb-1">Edit your profile</h4>
          </div>
          <div class="col-12 col-md-6">
            <div class="custom-group">
              <div class="photo-preview d-flex align-items-center">
                <div class="upload-photo mr-3" v-if="!image_url">
                  Your photo in here
                </div>
                <div class="flower mr-3" v-if="image_url" style="margin-bottom: .5rem">
                  <img :src="image_url" class="img-fluid round-image">
                </div>
                <div class="photo-action text-left">
                  <input type="file" ref="avatarFile" @change="inputFile" accept="image/x-png,image/gif,image/jpeg" style="display:none">
                  <button type="button" class="btn btn-primary" @click="$refs.avatarFile.click()">Upload your picture</button>
                  <h6 class="text-primary mt-2">Or select and photo profile from the photos on the right</h6>
                </div>
              </div>
              <div class="mt-3">
                <b-button type="button" class="btn-primary mr-3" @click="save">Submit</b-button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed1.png')" class="img-fluid" @click="setImage('wed1.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed2.png')" class="img-fluid" @click="setImage('wed2.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed3.png')" class="img-fluid" @click="setImage('wed3.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed4.png')" class="img-fluid" @click="setImage('wed4.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed5.png')" class="img-fluid" @click="setImage('wed5.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed6.png')" class="img-fluid" @click="setImage('wed6.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed7.png')" class="img-fluid" @click="setImage('wed7.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed8.png')" class="img-fluid" @click="setImage('wed8.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed9.png')" class="img-fluid" @click="setImage('wed9.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed10.png')" class="img-fluid" @click="setImage('wed10.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed11.png')" class="img-fluid" @click="setImage('wed11.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed12.png')" class="img-fluid" @click="setImage('wed12.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed13.png')" class="img-fluid" @click="setImage('wed13.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed14.png')" class="img-fluid" @click="setImage('wed14.png')">
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed15.png')" class="img-fluid" @click="setImage('wed15.png')">
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed16.png')" class="img-fluid" @click="setImage('wed16.png')">
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed17.png')" class="img-fluid" @click="setImage('wed17.png')">
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed18.png')" class="img-fluid" @click="setImage('wed18.png')">
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed19.png')" class="img-fluid" @click="setImage('wed19.png')">
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img :src="require('@/assets/img/wed20.png')" class="img-fluid" @click="setImage('wed20.png')">
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import {getApiManager} from "../../api";
  import {apiBaseUrl} from "../../constants/config";

  export default {
  data () {
    return {
      profile:{
        image:null,
        type:'default',
        profile_url:''
      },
      image_url:'',
    }
  },
  mounted() {
    getApiManager()
          .post(`${apiBaseUrl}/api/admin/profile/get-info`)
          .then((response) => {
            let res = response.data;
            if(res.status===true) {
              this.image_url = res.detail.profile_url?`${apiBaseUrl}/`+res.detail.profile_url:''
              this.profile.profile_url = ''
            }else{
              if(res.error==null){
                this.$notify({
                  group: 'foo',
                  type: 'error',
                  title: 'Error',
                  text: 'Server Error',
                  animation_type:'slide'
                });
              }
            }
          })
          .catch((error) => {
          })
  },
  methods:{
    setImage(item){
      this.profile.type = "default"
      this.profile.profile_url = item;
      this.profile.image = null;
      this.image_url= require('@/assets/img/'+item);
    },
    inputFile(event){
      this.profile.type = "random"
      this.profile.image = event.target.files[0];
      //set image to the div area
      var reader = new FileReader();
      var ref = this;
      reader.onload = function (e) {
        ref.image_url = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    save(){
      // if(this.profile.name=='') {
      //   this.$notify({
      //     group: 'foo',
      //     type: 'warn',
      //     title: 'Error',
      //     text: 'Please input a name.',
      //     animation_type:'slide'
      //   });
      //   return;
      // }
      // if(!validateEmail(this.profile.email)) {
      //   this.$notify({
      //     group: 'foo',
      //     type: 'warn',
      //     title: 'Error',
      //     text: 'Email format is incorrect.',
      //     animation_type:'slide'
      //   });
      //   return;
      // }
      // if(this.profile.new_password.length<6) {
      //   this.$notify({
      //     group: 'foo',
      //     type: 'warn',
      //     title: 'Error',
      //     text: 'New password should be more 6 characters.',
      //     animation_type:'slide'
      //   });
      //   return;
      // }
      // if(this.profile.new_password.length>30) {
      //   this.$notify({
      //     group: 'foo',
      //     type: 'warn',
      //     title: 'Error',
      //     text: 'New password should be less 30 characters.',
      //     animation_type:'slide'
      //   });
      //   return;
      // }
      // if(this.profile.new_password!=this.confirm_password) {
      //   this.$notify({
      //     group: 'foo',
      //     type: 'warn',
      //     title: 'Error',
      //     text: 'Please confirm the password.',
      //     animation_type:'slide'
      //   });
      //   return;
      // }
      if(this.profile.profile_url == '' && this.profile.image==null){
          this.$notify({
            group: 'foo',
            type: 'warn',
            title: 'Error',
            text: 'Please select an image.',
            animation_type:'slide'
          });
          return;
      }
      const formData = new FormData();
      for(let key in this.profile){
        formData.append(key,this.profile[key])
      }
        getApiManager()
            .post(`${apiBaseUrl}/api/admin/profile/modify`, formData)
            .then((response) => {
              let res = response.data;
              if(res.status===true){
                this.$notify({
                  group: 'foo',
                  type: 'success',
                  title: 'Success',
                  text: 'The profile was saved successfully.',
                  animation_type:'slide'
                });
                return;
              }
              else{
                this.$notify({
                  group: 'foo',
                  type: 'warn',
                  title: 'Error',
                  text: 'Server Error',
                  animation_type:'slide'
                });
                return;
              }
            })
            .catch((error) => {
              console.log(error)
            })
    },
  },
  components: {

  },
  computed: {
  },
  watch:{

  }
}
</script>
